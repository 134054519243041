<template>
  <div class="container-fuild">
    <div
      class="row"
      style="padding: 1.5rem;"
    >
      <div class="card col-12 col-sm-10 col-xl-9">
        <div class="card-body">
          <div class="mb-3">
            <h3>
              เพิ่ม/แก้ไข พนักงาน
            </h3>
          </div>
          <div class="row mb-1">
            <div class="col-12 col-sm-6 col-xl-5 text-center mb-2">
              <div class="mb-1">
                <b-img
                  rounded="circle"
                  alt="Circle image"
                  :src="pic"
                  style="max-width:209px;width:100%"
                  @error="setAltImg"
                />
              </div>
              <div class="text-left">
                <b-form-file
                  v-model="file"
                  placeholder="เพิ่มรูปพนักงาน"
                />
              </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-7 ">
              <validation-observer ref="employerRules">
                <table>
                  <thead>
                    <tr>
                      <th style="min-width:105px; width:30%;" />
                      <th style="width:70%;" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!typeStatus">
                      <td class="pr-1">
                        <p>ชื่อผู้ใช้</p>
                      </td>
                      <td>
                        <validation-provider
                          #default="{ errors }"
                          name="Username"
                          rules="required"
                        >
                          <b-form-input
                            id="h-first-name"
                            v-model="input.username"
                            :state="errors.length > 0 ? false:null"
                            autofocus
                          />
                          <small
                            v-if="errors.length > 0"
                            class="text-danger"
                          >กรุณากรอกชื่อผู้ใช้</small>
                        </validation-provider></td>
                    </tr>
                    <tr v-if="!typeStatus">
                      <td class="pr-1">
                        <p>รหัสผ่าน</p>
                      </td>
                      <td>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Password"
                            rules="required"
                          >
                            <!-- <b-input-group
                              class="input-group-merge"
                            > -->
                            <b-form-input
                              id="password"
                              v-model="input.password"
                              :type="passwordFieldType"
                              class="form-control-merge"
                              name="login-password"
                              :state="errors.length > 0 ? false:null"
                            />
                            <!-- <b-input-group-append is-text>
                                <feather-icon
                                  class="cursor-pointer"
                                  :icon="passwordToggleIcon"
                                  @click="togglePasswordVisibility"
                                />
                              </b-input-group-append> -->
                            <!-- </b-input-group> -->
                            <small
                              v-if="errors.length > 0"
                              class="text-danger"
                            >กรุณากรอกรหัสผ่าน</small>
                          </validation-provider>
                        </b-form-group>

                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <p>ชื่อ</p>
                      </td>
                      <td>
                        <validation-provider
                          #default="{ errors }"
                          name="FirstName"
                          rules="required"
                        >
                          <b-form-input
                            id="h-first-name"
                            v-model="input.firstName"
                            :readonly="typeStatus"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small
                            v-if="errors.length > 0"
                            class="text-danger"
                          >กรุณากรอกชื่อจริง</small>
                        </validation-provider></td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <p>นามสกุล</p>
                      </td>
                      <td>
                        <validation-provider
                          #default="{ errors }"
                          name="LastName"
                          rules="required"
                        >
                          <b-form-input
                            id="h-first-name"
                            v-model="input.lastName"
                            :state="errors.length > 0 ? false:null"
                            :readonly="typeStatus"
                          />
                          <small
                            v-if="errors.length > 0"
                            class="text-danger"
                          >กรุณากรอกชื่อจริง</small>
                        </validation-provider>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <p>วัน/เดือน/ปีเกิด</p>
                      </td>
                      <td>
                        <!-- <validation-provider
                          #default="{ errors }"
                          name="dateOfBirth"
                          rules="required"
                        > -->
                        <div style="margin-top: 8px;">
                          <DatePicker
                            :date="input.dateOfBirth"
                            :status="typeStatus"
                            @setDatePicker="onSetDatePicker"
                          />
                        </div>
                        <!-- <small
                            v-if="errors.length > 0"
                            class="text-danger"
                          >กรุณากรอกวันเดือนปีเกิด</small>
                        </validation-provider> -->
                      </td></tr>
                    <tr>
                      <td class="pr-1">
                        <p>เบอร์โทร</p>
                      </td>
                      <td>
                        <validation-provider
                          #default="{ errors }"
                          name="telephoneNumber"
                          rules="required"
                        >
                          <b-form-input
                            id="h-first-name"
                            v-model="input.telephoneNumber"
                            style="margin-top: -10px;"
                            type="number"
                            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small
                            v-if="errors.length > 0"
                            class="text-danger"
                          >กรุณากรอกเบอร์โทรศัพท์</small>
                        </validation-provider>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <p>
                          ตำแหน่ง
                        </p>
                      </td>
                      <td>
                        <validation-provider
                          #default="{ errors }"
                          name="Role"
                          rules="required"
                        >
                          <b-form-select
                            v-model="input.role"
                            :options="DataPosition"
                            :state="errors.length > 0 || input.role === null && checkRoleSelect !== true ? false:null"
                          />
                          <small
                            v-if="errors.length > 0 || checkRoleSelect === false"
                            class="text-danger"
                          >กรุณาเลือกตำแหน่ง</small>
                        </validation-provider>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <p>
                          คลัง
                        </p>
                      </td>
                      <td>
                        <validation-provider
                          #default="{ errors }"
                          name="Warehouse"
                          rules="required"
                        >
                          <b-form-select
                            v-model="input.warehouseId"
                            :options="warehuse"
                            :state="errors.length > 0 || input.role === null && checkWarehouseSelect !== true ? false:null"
                          />
                          <small
                            v-if="errors.length > 0 || checkWarehouseSelect === false"
                            class="text-danger"
                          >กรุณาเลือกคลังสินค้า</small>
                        </validation-provider>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <p>ที่อยู่</p>
                      </td>
                      <td colspan="2">
                        <validation-provider
                          #default="{ errors }"
                          name="Address"
                          rules="required"
                        >
                          <b-form-textarea
                            id="textarea-default"
                            v-model="input.address"
                            :state="errors.length > 0 ? false:null"
                            placeholder="ระบุที่อยู่"
                          />
                          <small
                            v-if="errors.length > 0"
                            class="text-danger"
                          >กรุณากรอกที่อยู่</small>
                        </validation-provider>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </validation-observer>
            </div>
          </div>
          <div class="mb-1 d-flex justify-content-center">
            <b-button
              variant="primary"
              class="mr-1"
              @click="onConfirm"
            >
              {{ textButtonName }}
            </b-button>
            <b-button
              variant="danger"
              style="width:95px"
              @click="onBackButton()"
            >
              ย้อนกลับ
            </b-button>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { BImg, BButton, BFormInput, BFormSpinbutton, BFormSelect, BFormFile, BFormTextarea, BFormDatepicker, BFormGroup, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import DatePicker from '@/views/components/production/datePicker.vue'
import router from '@/router'
import PlaceHolder from '@/assets/images/production/user.png'

export default defineComponent({
  name: 'employee_add',
  components: {
    BImg,
    BButton,
    BFormInput,
    BFormSpinbutton,
    BFormSelect,
    BFormFile,
    BFormTextarea,
    BFormDatepicker,
    DatePicker,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    required,
    ValidationProvider,
    ValidationObserver

  },
  methods: {
    // removeEmployer (id) {
    //   if (id !== '' && id !== undefined) {
    //     this.$swal({
    //       title: 'คุณต้องการจะลบข้อมูลนี้?',
    //       text: 'คุณมั่นใจรึเปล่า ? คุณจะไม่สามารถนำข้อมูลนี้กลับมาได้!',
    //       type: 'warning',
    //       icon: 'warning',
    //       showCancelButton: true,
    //       confirmButtonClass: 'btn-success mr-1',
    //       confirmButtonText: 'ยืนยัน',
    //       cancelButtonClass: 'btn-danger',
    //       cancelButtonText: 'ยกเลิก'
    //     }).then(result => {
    //       if (result.value) {
    //         this.$store.dispatch('employer/DeleteEmployer', { id })
    //           .then(response => {
    //             if (response.data.success) {
    //               this.$toast({
    //                 component: ToastificationContent,
    //                 position: 'top-right',
    //                 props: {
    //                   title: 'พนักงาน',
    //                   icon: 'CoffeeIcon',
    //                   variant: 'success',
    //                   text: response.data.message
    //                 }
    //               })
    //               router.push('/employee/list')
    //             } else {
    //               this.$toast({
    //                 component: ToastificationContent,
    //                 position: 'top-right',
    //                 props: {
    //                   title: 'พนักงาน',
    //                   icon: 'CoffeeIcon',
    //                   variant: 'danger',
    //                   text: response.data.message
    //                 }
    //               })
    //             }
    //           }).catch(error => {
    //             this.$toast({
    //               component: ToastificationContent,
    //               position: 'top-right',
    //               props: {
    //                 title: 'ลูกค้า',
    //                 icon: 'CoffeeIcon',
    //                 variant: 'danger',
    //                 text: error.message
    //               }
    //             })
    //           })
    //       }
    //     })
    //   }
    // },
    onSetDatePicker (val) {
      this.input.dateOfBirth = val.val
    },
    onConfirm () {
      if (this.typeStatus) {
        this.onClickUpdateEmployer()
      } else this.onClickAddEmployer()
    },
    onClickUpdateEmployer () {
      this.$refs.employerRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('employer/editEmployer', this.input).then(result => {
            if (result.data.success) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: result.data.message
                }
              })
              this.uploadPic()
              router.push('/employee/list')
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: result.data.message
                }
              })
            }
          })
        }
      })
    },
    onClickAddEmployer () {
      this.$refs.employerRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('employer/AddEmployer', this.input).then(result => {
            if (result.data.success) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: result.data.message
                }
              })
              this.input.id = result.id
              this.uploadPic()
              router.push('/employee/list')
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: result.data.message
                }
              })
            }
          })
        }
      })
    },
    uploadPic () {
      if (this.file !== null) {
        const formData = new FormData()
        formData.append('employer', this.file)
        formData.append('id', this.input.id)
        this.$store.dispatch('upload/uploadEmployer', formData).then(success => {
          console.log(success)
        })
      }
    },
    onBackButton () {
      this.$router.push({ name: 'employee_list' })
    },
    setAltImg (e) {
      console.log('alt')
      e.target.src = PlaceHolder
    }
  },
  mixins: [togglePasswordVisibility],

  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    textButtonName () {
      return this.typeStatus ? 'เเก้ไข' : 'ยืนยัน'
    }
  },
  watch: {
    file (e) {
      const newfile = e
      this.pic = URL.createObjectURL(newfile)
    },
    '$route.params.code': {
      handler (item) {
        if (item) {
          this.typeStatus = true
        } else {
          this.typeStatus = false
          this.input = {
            id: '',
            firstName: '',
            lastName: '',
            warehouseId: '',
            role: '',
            telephoneNumber: '',
            username: '',
            password: '',
            address: '',
            dateOfBirth: '1999-01-01'
          }
        }
      },
      'input.role': {
        handler (val) {
          if (val !== null) {
            this.checkIsSelect = true
          }
        }
      }
    }
  },

  created () {
    this.$store.dispatch('warehouse/getWareHouse', this.input).then(result => {
      this.warehuse = [{ value: '', text: 'เลือกคลัง' }]
      result.data.items.forEach(data => {
        this.warehuse.push({ value: data._id, text: data.name })
      })
      this.$store.dispatch('setting/getPosition').then(result => {
        this.DataPosition = [{ value: '', text: 'เลือกตำแหน่ง' }]
        result.data.items.forEach(element => {
          this.DataPosition.push({ value: element._id, text: element.name })
        })
      })
    })
    if (this.$route.params.code !== '' && this.$route.params.code !== undefined) {
      this.$store.dispatch('employer/getEmployerCode', { code: this.$route.params.code }).then(result => {
        this.typeStatus = true
        const datas = result.data.items[0]
        this.input.id = datas._id
        this.input.firstName = datas.firstName
        this.input.lastName = datas.lastName
        this.input.warehouseId = datas.warehouseId
        this.input.role = datas.role._id
        this.input.telephoneNumber = datas.telephoneNumber
        this.input.address = datas.address
        this.input.dateOfBirth = datas.dateOfBirth
        this.pic = this.$baseURL + datas.pic
      })
    } else this.typeStatus = false
  },
  data () {
    return {
      typeStatus: false,
      textButton: '',
      file: null,
      input: {
        id: '',
        firstName: '',
        lastName: '',
        warehouseId: '',
        role: '',
        telephoneNumber: '',
        username: '',
        password: '',
        address: '',
        dateOfBirth: '1999-01-01'
      },
      checkRoleSelect: true,
      checkWarehouseSelect: true,
      pic: '',

      warehuse: [],
      DataPosition: [
        { value: '', text: 'เลือกตำแหน่ง' },
        { value: 'หัวหน้า', text: 'หัวหน้า' },
        { value: 'พนักงานส่งของ', text: 'พนักงานส่งของ' },
        { value: 'ฝ่ายขาย', text: 'ฝ่ายขาย' }
      ]
    }
  }
})
</script>
<style scoped>
  p {
    margin-top:11px
}
</style>
