var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fuild"},[_c('div',{staticClass:"row",staticStyle:{"padding":"1.5rem"}},[_c('div',{staticClass:"card col-12 col-sm-10 col-xl-9"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-12 col-sm-6 col-xl-5 text-center mb-2"},[_c('div',{staticClass:"mb-1"},[_c('b-img',{staticStyle:{"max-width":"209px","width":"100%"},attrs:{"rounded":"circle","alt":"Circle image","src":_vm.pic},on:{"error":_vm.setAltImg}})],1),_c('div',{staticClass:"text-left"},[_c('b-form-file',{attrs:{"placeholder":"เพิ่มรูปพนักงาน"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)]),_c('div',{staticClass:"col-12 col-sm-6 col-xl-7 "},[_c('validation-observer',{ref:"employerRules"},[_c('table',[_c('thead',[_c('tr',[_c('th',{staticStyle:{"min-width":"105px","width":"30%"}}),_c('th',{staticStyle:{"width":"70%"}})])]),_c('tbody',[(!_vm.typeStatus)?_c('tr',[_c('td',{staticClass:"pr-1"},[_c('p',[_vm._v("ชื่อผู้ใช้")])]),_c('td',[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-first-name","state":errors.length > 0 ? false:null,"autofocus":""},model:{value:(_vm.input.username),callback:function ($$v) {_vm.$set(_vm.input, "username", $$v)},expression:"input.username"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกชื่อผู้ใช้")]):_vm._e()]}}],null,false,1833092522)})],1)]):_vm._e(),(!_vm.typeStatus)?_c('tr',[_c('td',{staticClass:"pr-1"},[_c('p',[_vm._v("รหัสผ่าน")])]),_c('td',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"name":"login-password","state":errors.length > 0 ? false:null},model:{value:(_vm.input.password),callback:function ($$v) {_vm.$set(_vm.input, "password", $$v)},expression:"input.password"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกรหัสผ่าน")]):_vm._e()]}}],null,false,80694854)})],1)],1)]):_vm._e(),_c('tr',[_c('td',{staticClass:"pr-1"},[_c('p',[_vm._v("ชื่อ")])]),_c('td',[_c('validation-provider',{attrs:{"name":"FirstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-first-name","readonly":_vm.typeStatus,"state":errors.length > 0 ? false:null},model:{value:(_vm.input.firstName),callback:function ($$v) {_vm.$set(_vm.input, "firstName", $$v)},expression:"input.firstName"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกชื่อจริง")]):_vm._e()]}}])})],1)]),_c('tr',[_c('td',{staticClass:"pr-1"},[_c('p',[_vm._v("นามสกุล")])]),_c('td',[_c('validation-provider',{attrs:{"name":"LastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-first-name","state":errors.length > 0 ? false:null,"readonly":_vm.typeStatus},model:{value:(_vm.input.lastName),callback:function ($$v) {_vm.$set(_vm.input, "lastName", $$v)},expression:"input.lastName"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกชื่อจริง")]):_vm._e()]}}])})],1)]),_c('tr',[_c('td',{staticClass:"pr-1"},[_c('p',[_vm._v("วัน/เดือน/ปีเกิด")])]),_c('td',[_c('div',{staticStyle:{"margin-top":"8px"}},[_c('DatePicker',{attrs:{"date":_vm.input.dateOfBirth,"status":_vm.typeStatus},on:{"setDatePicker":_vm.onSetDatePicker}})],1)])]),_c('tr',[_c('td',{staticClass:"pr-1"},[_c('p',[_vm._v("เบอร์โทร")])]),_c('td',[_c('validation-provider',{attrs:{"name":"telephoneNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"margin-top":"-10px"},attrs:{"id":"h-first-name","type":"number","pattern":"[0-9]{3}-[0-9]{2}-[0-9]{3}","state":errors.length > 0 ? false:null},model:{value:(_vm.input.telephoneNumber),callback:function ($$v) {_vm.$set(_vm.input, "telephoneNumber", $$v)},expression:"input.telephoneNumber"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกเบอร์โทรศัพท์")]):_vm._e()]}}])})],1)]),_c('tr',[_c('td',{staticClass:"pr-1"},[_c('p',[_vm._v(" ตำแหน่ง ")])]),_c('td',[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.DataPosition,"state":errors.length > 0 || _vm.input.role === null && _vm.checkRoleSelect !== true ? false:null},model:{value:(_vm.input.role),callback:function ($$v) {_vm.$set(_vm.input, "role", $$v)},expression:"input.role"}}),(errors.length > 0 || _vm.checkRoleSelect === false)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณาเลือกตำแหน่ง")]):_vm._e()]}}])})],1)]),_c('tr',[_c('td',{staticClass:"pr-1"},[_c('p',[_vm._v(" คลัง ")])]),_c('td',[_c('validation-provider',{attrs:{"name":"Warehouse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.warehuse,"state":errors.length > 0 || _vm.input.role === null && _vm.checkWarehouseSelect !== true ? false:null},model:{value:(_vm.input.warehouseId),callback:function ($$v) {_vm.$set(_vm.input, "warehouseId", $$v)},expression:"input.warehouseId"}}),(errors.length > 0 || _vm.checkWarehouseSelect === false)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณาเลือกคลังสินค้า")]):_vm._e()]}}])})],1)]),_c('tr',[_c('td',{staticClass:"pr-1"},[_c('p',[_vm._v("ที่อยู่")])]),_c('td',{attrs:{"colspan":"2"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","state":errors.length > 0 ? false:null,"placeholder":"ระบุที่อยู่"},model:{value:(_vm.input.address),callback:function ($$v) {_vm.$set(_vm.input, "address", $$v)},expression:"input.address"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกที่อยู่")]):_vm._e()]}}])})],1)])])])])],1)]),_c('div',{staticClass:"mb-1 d-flex justify-content-center"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.onConfirm}},[_vm._v(" "+_vm._s(_vm.textButtonName)+" ")]),_c('b-button',{staticStyle:{"width":"95px"},attrs:{"variant":"danger"},on:{"click":function($event){return _vm.onBackButton()}}},[_vm._v(" ย้อนกลับ ")])],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[_c('h3',[_vm._v(" เพิ่ม/แก้ไข พนักงาน ")])])}]

export { render, staticRenderFns }